<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1>Чат</h1>
        <MessagingWindow
          :messages="messages"
          :all-loaded="allMessageShown"
          :chat-id="chatId"
          v-on:show-more="loadMessages(chatId)"
          v-on:send-message="doSendMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MessagingWindow from "../components/MessagingWindow.vue";
import axios from "axios";

export default {
  name: "chat-offer",
  data() {
    return {
      messages: [],
      allMessageShown: false,
    };
  },
  components: { MessagingWindow },
  computed: {
    chatId() {
      return this.$route.params["id"];
    },

    orderedMessages() {
      let messages = [...this.messages];
      return messages.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
  },

  methods: {
    ...mapActions(["sendMessage"]),

    async doSendMessage(obj) {
      let newMessage = await this.sendMessage(obj);
      if (newMessage) {
        this.messages.push(newMessage);
      }
    },

    async loadMessages(chatId) {
      try {
        let messages = (
          await axios.get(
            `https://vp.bw2api.ru/api/v1/chat/message?chat=${chatId}&offset=${this.messages.length}`
          )
        ).data;

        this.messages = [...messages, ...this.messages];

        if (messages.length == 0) {
          this.allMessageShown = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.loadMessages(this.chatId);
  },
};
</script>
